<template>
    <div class="SurplusDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form :id="formId" ref="form" label-width="80px" width="auto" size="small">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="机构类型">
                                <el-input v-model="form.deptTypeName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="机构名称">
                                <el-input v-model="form.deptName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <ef-abstract :type="adjustAbstractType" v-model="form.abstractCode" disabled />
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建人">
                                <el-input v-model="form.creator" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="创建日期">
                                <el-input v-model="form.createTime" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="盘点编码" v-if="form.stockCheckCode">
                                <el-input
                                    placeholder="请填写"
                                    v-model="form.stockCheckCode"
                                    style="width: 215px"
                                    readonly
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <ef-remark v-model="form.remark" readonly />
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div style="height: 32px">
                <el-button type="primary" @click="handleAudit" v-if="form.reviewStatus == 0 ? false : true" size="small"
                    >审核详情</el-button
                >
                <el-button style="float: right; margin-top: 3px" size="mini" @click="handlePrint">打印</el-button>
                <PickupReturnAudit ref="PickupReturnAudit" :review-privilege-flag="'menu.stock.surplus.review'" />
            </div>
        </el-card>
        <el-card style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="form.details"
                v-loading="tableLoading"
                size="mini"
                show-summary
                :highlight-current-row="true"
                max-height="440"
                :id="tableId"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="商品名称" width="180">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).name }}
                    </template>
                </el-table-column>
                <el-table-column label="条码">
                    <template slot-scope="scope">
                        {{
                            skuByGoodsCode(scope.row.goodsCode)
                                .bars.map((d) => d)
                                .join(' / ')
                        }}
                    </template>
                </el-table-column>
                <el-table-column label="商品类型" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).type | type }}
                    </template>
                </el-table-column>
                <el-table-column label="助记码" width="100">
                    <template slot-scope="scope">
                        {{ goodsByCode(scope.row.goodsCode).fastBar }}
                    </template>
                </el-table-column>
                <el-table-column label="自编码" width="100">
                    <template slot-scope="scope">
                        {{ goodsByCode(scope.row.goodsCode).customBar }}
                    </template>
                </el-table-column>
                <el-table-column label="规格" width="180">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                    </template>
                </el-table-column>
                <el-table-column label="类目" width="180">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).category }}
                    </template>
                </el-table-column>
                <el-table-column label="单位" width="180">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                    </template>
                </el-table-column>
                <el-table-column label="品牌" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="showPrices"
                    v-if="hasPrivilege('biz.price.wholeSale.query') && showInPrice()"
                    label="批发价快照"
                    width="100"
                />
                <el-table-column prop="counts" label="调整数量" width="80" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import Util from 'js/Util';
import GoodsCommon from 'js/GoodsCommon';
import StockBizCommon, { PropertyConverter } from 'js/StockBizCommon';
import EfAbstract from 'components/EfAbstract';
import EfRemark from 'components/EfRemark';
import PickupReturnAudit from '../../../components/PickupReturnAudit';
import MoneyUtils from 'js/MoneyUtils';

export default {
    name: 'SurplusDetail',
    components: { EfAbstract, EfRemark, PickupReturnAudit },

    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            baseUrl: this.$efApi.stockSurplusApi.baseUrl,
            adjustAbstractType: StockBizCommon.AdjustAbstractTypeEnum.OVERFLOW,
            form: {
                code: '',
                deptCode: '',
                deptName: '',
                deptTypeName: '',
                abstractCode: '',
                abstractName: '',
                remark: '',
                createTime: '',
                creator: '',
                details: [],
                stockCheckCode: '',
            },
            audit: {
                code: this.code,
                bizCode: '',
                auditDate: Util.nowFormatDate(),
                advice: '',
                remark: '',
            },
            goodsDetailMap: new Map(),
            tableLoading: true,
            //id标识
            formId: 'form',
            tableId: 'table',
        };
    },
    mounted() {
        (async () => {
            //查询数据
            const rst = await this.$efApi.stockSurplusApi.detail(this.code);
            Util.copyProperties(rst, this.form);
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                rst,
                this.detailParamPropertyArrForShow()
            );
            this.form.details = arr.detailParams;
            this.goodsDetailMap = arr.goodsDetails;
            this.tableLoading = false;
        })();
    },
    methods: {
        handlePrint() {
            this.printIdSelectorHtml(
                [
                    { id: this.formId, type: 'form' },
                    { id: this.tableId, type: 'table' },
                ],
                this.$route.meta.title || ''
            );
        },
        detailParamPropertyArrForShow() {
            return [
                'codes',
                'goodsCode',
                'counts',
                new PropertyConverter('wholeSalePrices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
            ];
        },
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.code, this.baseUrl, this.form.stockStatus);
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
    },
    filters: {
        type(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
